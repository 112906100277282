import { lazy, Suspense, useEffect, useState } from "react";
const About = lazy(() => import('./components/about'));
const BackToTop = lazy(() => import('./components/backToTop'));
const Contact = lazy(() => import('./components/contact'));
const EthosStatements = lazy(() => import('./components/ethos'));
const Faq = lazy(() => import('./components/faq'));
const Footer = lazy(() => import('./components/footer'));
const HowToOrder = lazy(() => import('./components/howToOrder'));
const HeroSection = lazy(() => import('./components/main'));
const Products = lazy(() => import('./components/product'));
const WIP = lazy(() => import('./components/wip'));


function App() {
  const [showWIP, setShowWIP] = useState(false);

  useEffect(() => {
    const wip = window?.location?.search?.includes("wip");
    setShowWIP(wip);
  }, []);

  const handleWIP = (state: boolean) => {
    console.log("WIP Trigger");
    setShowWIP((pre) => !pre);
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="app">
        {!showWIP ? (
          <div>
            <HeroSection showWIP={handleWIP} />
            <Products />
            <About />
            <Faq />
            <HowToOrder />
            <EthosStatements />
            <Contact />
            <Footer />
            <BackToTop />
          </div>
        ) : (
          <WIP showWIP={handleWIP} />
        )}
      </div>
    </Suspense>
  );
}

export default App;
